import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, Box, Grid, Button, useMediaQuery, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  quickStartSection: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    }
  },
  fontWeightBold: {
    fontWeight: '900',
  },
  editor: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
    borderRadius: theme.spacing(1/2),
    width: '100%',
  },
  logoImg: {
    maxWidth: 100,
  },
}));

const doohImage = require('assets/images/dooh/flughafen/dooh-flughafen-digital-werbescreen.jpeg');

const Dooh = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const docsButton = (
    <Button size="large" variant="outlined" color="primary" component="a" href="/documentation">
      Documentation
    </Button>
  );

  const buyButton = (
    <Button
      size="large"
      variant="contained"
      color="primary"
      component="a"
      href="/home"
    >
      Get Started
    </Button>
  );

  return (
    <div className={className} {...rest}>
      <Grid container justify="space-between" spacing={4}>
        <Grid item xs={12}>
          <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={6} data-aos={'fade-right'}>
              <SectionHeader
                title="DooH"
                subtitle="Digital-Out-Of-Home "
                //ctaGroup={[docsButton, buyButton]}
                align={isMd ? 'left' : 'center'}
                disableGutter
                titleVariant="h3"
                titleProps={{ className: classes.fontWeightBold }}
                className={classes.quickstartSection}
              />
              <Typography color="textPrimary" variant="body1">
                Digitale Außenwerbung, engl. Digital-out-of-Home (DooH) - Bewegtbild im Out-of-Home-Bereich bedeutet 
                zunächst erhöhte Sichtbarkeit sowie Aufmerksamkeit. Diese Art der Werbung profitiert davon, dass selbst 
                kleine Bewegungsreize für das menschliche Wahrnehmungssystem absolut unwiderstehlich sind. DOOH wird somit 
                regelrecht zum Aufmerksamkeitsfänger: Bewegung bedeutet Beute, Belohnung  – oder auch Gefahr. 
                In jedem Fall etwas, das Beachtung verdient.
              </Typography>
              <br />
              <Typography color="textSecondary" variant="body2">
                Quelle: <Link style={{color: theme.palette.text.secondary}} target="_blank" to="https://www.horizont.net/medien/kommentare/dooh-welche-chancen-digitale-aussenwerbung-fuer-die-kreation-bietet-178983">Horizont</Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} data-aos={'fade-right'}>
              <Box display="flex" justifyContent="space-between" alignItems="center" padding={1} border="1px solid #ccc" borderRadius="4px">
                <Image src={ doohImage } />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Dooh.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Dooh;
