import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { GetStarted, Dooh, Benefits, Hero, Customers, Locations } from './components';
import { partners, locations } from './data';

const useStyles = makeStyles(() => ({
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const IndexView = ({ themeMode }) => {
  const classes = useStyles();

  return (
    <div>
      <Hero themeMode={themeMode} />
      <SectionAlternate className={classes.dividerSection}>
        <Customers data={partners} />
      </SectionAlternate>
      <Benefits />
      <SectionAlternate >
        <Dooh />
      </SectionAlternate>
      <Section>
        <Locations data={locations} />
      </Section>
      <SectionAlternate>
        <GetStarted />
      </SectionAlternate>
    </div>
  );
};

export default IndexView;
