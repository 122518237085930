import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, colors } from '@material-ui/core';
import { IconAlternate, SectionHeader } from 'components/molecules';
import { DescriptionListIcon, Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  smallPaddingTop: {
    paddingTop: 35,
  },
  smallPaddingBottom: {
    paddingBottom: 10,
  }
}));

const data = [
  {
    icon: 'fas fa-chalkboard-teacher',
    title: 'Pay-per-Count',
    subtitle:
      'Wir bieten als eine der ersten deutschen DooH-Unternehmen die Pay-per-Count Zahlvariante an. Für Sie entstehen nur dann Kosten, wenn unser Personenzählsystem Menschen gezählt hat, während ihr Werbespot lief.',
  },
  {
    icon: 'fas fa-store',
    title: 'Gut besuchte Locations',
    subtitle:
      'Wir sind spezialisiert auf gut besuchte Standorte in einer hoch frequentierten Umgebung. Das macht unsere Werbeflächen für die Werbenden qualitativ sowie attraktiv.',
  },
  {
    icon: 'fas fa-stopwatch-20',
    title: 'In 20 Sekunden live',
    subtitle:
      "Nachdem wir alle Buchungsdetails geklärt haben, können wir Ihre Werbung innerhalb von 20 Sekunden auf die gewünschte Werbefläche hochladen und abspielen lassen.",
  },
];
const Benefits = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
   
  const title = (
    <Typography variant="h2" component="span" className={classes.fontWeight900}>
      Mieten Sie&nbsp;
      <Typography component="span" variant="inherit" color="primary">digitale Werbeflächen</Typography>
      &nbsp;für Ihr Werbespot
    </Typography>
  );

  const subtitle = 'Mit unseren modernen und zukunftsorientierten digitalen Werbeflächen bieten wir Unternehmen die Möglichkeit, in gut besuchten Lokalen Ihre Werbespots zu schalten und somit viele potentielle Interessenten zu erreichen.';

  return (
    <div className={className} {...rest}>
      <Section narrow className={classes.smallPaddingBottom}>
        <SectionHeader
          title={title}
          subtitle={subtitle}
          align="center"
          titleProps={{
            variant: 'h2',
            color: 'textPrimary',
          }}
          data-aos="fade-up"
        />
      </Section>
      <Section className={classes.smallPaddingTop}>
        <Grid container spacing={isMd ? 4 : 2}>
          {data.map((item, index) => (
            <Grid key={index} item xs={12} sm={4} data-aos={'fade-up'} style={!isMd ? {marginBottom:50} : {} }>
              <DescriptionListIcon
                title={item.title}
                subtitle={item.subtitle}
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    size="medium"
                    color={colors.amber}
                  />
                }
                align="left"
              />
            </Grid>
          ))}
        </Grid>
      </Section>
    </div>
  );
};

Benefits.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Benefits;
