export const partners = [
  {
    name: 'Allianz - Kai Kolepka',
    logo: require('assets/images/kunden/dooh/allianz.svg')
  },
  {
    name: 'HeiProTec',
    logo: require('assets/images/kunden/dooh/heiprotec.svg')
  },
  {
    name: 'Auto Kul',
    logo: require('assets/images/kunden/dooh/auto-kul.svg')
  },
  {
    name: 'Beauty Salon Cologne',
    logo: require('assets/images/kunden/dooh/beauty-smile-cologne.svg')
  }
];

export const locations = [
  {
    images: [
      {
        src: require('assets/images/location/derbarber/derbarber1.jpg'),
        srcSet: require('assets/images/location/derbarber/derbarber1@2x.jpg'),
        alt: 'Innenbereich',
      },
      {
        src: require('assets/images/location/derbarber/derbarber2.jpg'),
        srcSet: require('assets/images/location/derbarber/derbarber2@2x.jpg'),
        alt: 'Schaufenster',
      },
      {
        src: require('assets/images/location/derbarber/derbarber3.jpg'),
        srcSet: require('assets/images/location/derbarber/derbarber3@2x.jpg'),
        alt: 'Schaufenster',
      },
      {
        src: require('assets/images/location/derbarber/derbarber4.jpg'),
        srcSet: require('assets/images/location/derbarber/derbarber4@2x.jpg'),
        alt: 'Schaufenster',
      },
      {
        src: require('assets/images/location/derbarber/derbarber5.jpg'),
        srcSet: require('assets/images/location/derbarber/derbarber5@2x.jpg'),
        alt: 'Innenbereich',
      }
    ],
    title: 'Der Barber - Marktpassage Siegburg',
    address: 'Markt 16, 53721 Siegburg',
    price: 'mtl. ~50k Sichtkontakte',
    url: '/locations/der-barber',
    urlTitle: 'Erfahre mehr',
    reviews: [
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
        },
        authorName: 'Veronica Adams',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
        },
        authorName: 'Akachi Luccini',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
        },
        authorName: 'Jack Smith',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
        },
        authorName: 'Kate Segelson',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x',
        },
        authorName: 'Alex Johnson',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x',
        },
        authorName: 'Valeria Kogan',
      },
    ],
    score: '5.0',
    reviewCount: 28,
  },
  {
    images: [
      {
        src: require('assets/images/location/meemobile/meemobile-coming-soon.jpg'),
        srcSet: require('assets/images/location/meemobile/meemobile-coming-soon@2x.jpg'),
        alt: 'MeeMobile Bremen',
      }
    ],
    title: 'Mee Mobile - Mobilfunk-Store',
    address: 'Weserstr. 1, 28779 Bremen',
    price: 'Analyse &- Aufbauphase',
    url: '#meemobile-comingsoon',
    urlTitle: 'Bald verfügbar',
    reviews: [
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
        },
        authorName: 'Veronica Adams',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
        },
        authorName: 'Akachi Luccini',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
        },
        authorName: 'Jack Smith',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
        },
        authorName: 'Kate Segelson',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x',
        },
        authorName: 'Alex Johnson',
      },
      {
        authorPhoto: {
          src: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg',
          srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x',
        },
        authorName: 'Valeria Kogan',
      },
    ],
    score: '4.9',
    reviewCount: 38,
  }
];